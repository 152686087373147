import { Injectable } from "@angular/core";
import { MenuItem } from "primeng/api";
export const  permissionsMenu: { link?: string; label: string; permissions: string[] }[] = [
    {
        link: "/operator/",
        permissions: ["Dashboard"],
        label: "Ana Sayfa",
    },
    {
        link: "/settings/version-settings",
        permissions: ["VersionSettings"],
        label: "Versiyonlar",
    },
    {
        link: "/operator/payment-control",
        permissions: ["Finance.Payment.List"],
        label: "Ödeme Mutabakat",
    },
    {
        link: "/operator/bill-list",
        permissions: ["Finance.Bill.List"],
        label: "Fatura Takip",
    },
    {
        permissions: ["Finance"],
        label: "Muhasebe",
    },
    {
        link: "/operator/request",
        permissions: ["Request"],
        label: "Çağrı Karşılama",
    },
    {
        link: "/operator/file-list",
        permissions: ["File-List"],
        label: "Dosya Listesi",
    },
    {
        link: "/operator/service-list",
        permissions: ["Service-List"],
        label: "Hizmet Listesi",
    },
    {
        link: "/operator/operations",
        permissions: ["Operations"],
        label: "Operasyon Kontrol",
    },
    {
        link: "/operator/complaint",
        permissions: ["Complaint"],
        label: "Şikayet Kaydı",
    },
    {
        link: "/operator/activateService",
        permissions: ["ActivateService"],
        label: "Servis Aktifleştirme",
    },
    {
        link: "/operator/announcements",
        permissions: ["Show.Announcements"],
        label: "Duyurular",
    },
    {
        link: "/log-list",
        permissions: ["AnnouncementLogFollow"],
        label: "Bildirim Log Takibi",
    },
   
    {
        link: "/operator/octo-map",
        permissions: ["Show-MapMenuBar"],
        label: "Tedarikçi Haritası",
    },
    {
        link: "/operator/scripts",
        permissions: ["Show.Scripts"],
        label: "Scriptler",
    },
    {
        permissions: ["Process"],
        label: "İşlemler",
    },
    {
        link: "/settings/customers",
        permissions: ["Customers"],
        label: "Müşteriler",
    },
    {
        link: "/settings/counters-table",
        permissions: ["Counters-List"],
        label: "Counters Tanımları",
    },
    {
        link: "/settings/table/cities",
        permissions: ["Cities-List"],
        label: "Şehir Tanımları",
    },
    {
        link: "/settings/table/countries",
        permissions: ["Countries-List"],
        label: "Ülke Tanımları",
    },
    {
        link: "/settings/table/towns",
        permissions: ["Towns-List"],
        label: "İlçe Tanımları",
    },
    {
        link: "/settings/districts",
        permissions: ["Districts-List"],
        label: "Mahalle Tanımları",
    },
    {
        link: "/settings/areas",
        permissions: ["Region-List"],
        label: "Bölge Tanımları",
    },
    {
        link: "/settings/workingHours",
        permissions: ["Working-Hours-List"],
        label: "Çalışma Saatleri",
    },
    {
        link: "/settings/holidays",
        permissions: ["Holidays-List"],
        label: "Resmi Tatiller",
    },
    {
        link: "/settings/levels",
        permissions: ["Levels-List"],
        label: "Seviye Tanımları",
    },
    {
        permissions: ["GlobalDefinition"],
        label: "Genel Tanımlar",
    },
    {   
        link: "/settings/products",
        permissions: ["GlobalDefinition.Products"],
        label: "Ürünler",
    },
    {
        link: "/settings/table/customertype",
        permissions: ["GlobalDefinition.Customertypes"],
        label: "Müşteri Tipleri",
    },
    {
        link: "/settings/assurances",
        permissions: ["GlobalDefinition.Assurances"],
        label: "Teminatlar",
    },
    {
        link: "/settings/table/customernotes",
        permissions: ["GlobalDefinition.CustomerNotes"],
        label: "Özel Notlar",
    },
    {
        link: "/parameters",
        permissions: ["GlobalDefinition.Parameters"],
        label: "Parametreler",
    },
    {
      permissions: ["SupplierDefinitions"],
      label: "Tedarikçi Tanımları",
    },
    {
      link: "/settings/suppliers",
      permissions: ["SupplierDefinitions.Suppliers"],
      label: "Tedarikçiler",
    },
    {
      link: "/supplier-staff",
      permissions: ["SupplierDefinitions.SupplierStaffs"],
      label: "Tedarikçi Çalışanları",
    },
    {
      link: "/supplier-car",
      permissions: ["SupplierDefinitions.SupplierVehicles"],
      label: "Tedarikçi Araçları",
    },
    {
        link: "/settings/supplier-quota-list",
        permissions: ["Supplier-Quota-List"],
        label: "Tedarikçi Kota Listesi",
    },
    {
        permissions: ["VehicleDefinition"],
        label: "Araç Tanımları",
    },
    {
        permissions: ["AutoDefinition"],
        label: "Otomobil Tanımları",
        link:"/settings/auto"
    },
    {
        permissions: ["TowVehicleType"],
        label: "Çekici Araç Tipleri",
        link:"/settings/table/towvehicletype"
    },
    {
        permissions: ["VehicleType"],
        label: "Araç Tipleri",
        link:"/settings/table/vehicletype"
    },
    {
        permissions: ["VehicleGear"],
        label: "Vites Tipleri",
        link:"/settings/table/vehiclegears"
    },
    {
        permissions: ["VehicleFuel"],
        label: "Yakıt Tipleri",
        link:"/settings/table/fueltypes"
    },
    {
        permissions: ["VehicleColors"],
        label: "Araç Renkleri",
        link:"/settings/table/vehiclecolors"
    },

    {
        permissions: ["VehicleFeatures"],
        label: "Araç Özellikleri",
        link:"/settings/table/vehiclefeatures"
    },
    {
        permissions: ["SegmentTypes"],
        label: "Segment Tipleri",
        link:"/settings/table/segmenttypes"
    },
    {
        permissions: ["SegmentMAPDefinition"],
        label: "Segment Map Tanımları",
        link:"/settings/segment-maping"
    },




    {
        permissions: ["OccurrenceDefinitions"],
        label: "Dosya Tanımları",
    },
    {
        permissions: ["OccurrenceTypes"],
        label: "Bildirim Tipleri",
        link:"/settings/table/occurrencetype"
    },
    {
        permissions: ["OccurrenceSubTypes"],
        label: "Bildirim Alt Tipleri",
        link:"/settings/table/occurrencesubtype"
    },
    {
        permissions: ["FileTypes"],
        label: "Dosya Tipleri",
        link:"/settings/table/filetype"
    },
    {
        permissions: ["FileStatuses"],
        label: "Dosya Durumları",
        link:"/settings/table/filestatus"
    },
    {
        permissions: ["FileOpenReasons"],
        label: "Tekrar Dosya Açma Nedenleri",
        link:"/settings/table/fileopenreason"
    },
    {
        permissions: ["FileClosedReasons"],
        label: "Dosya Tamamlama Nedenleri",
        link:"/settings/table/fileclosereason"
    },
    {
        permissions: ["FileCanceledReasons"],
        label: "Dosya İptal Nedenleri",
        link:"/settings/table/filecancelreason"
    },
    {
        permissions: ["FileNotesTypes"],
        label: "Dosya Notu Tipleri",
        link:"/settings/table/filenotetypes"
    },
    {
        permissions: ["TransactionChannels"],
        label: "İşlem Kanalları",
        link:"/settings/table/transactionchannel"
    },
    {
        permissions: ["NotificationsChannels"],
        label: "Bildirim Kanalları",
        link:"/settings/table/notificationchannel"
    },
    {
        link: "/settings/table/locationtypes",
        permissions: ["Show.ServiceLocations"],
        label: "Lokasyon Tipleri",
    },
    {
        link: "/settings/octoservicelocation",
        permissions: ["Show.ServiceLocations"],
        label: "Hizmet Lokasyonları",
    },
    {
        link: "/settings/table/slacategories",
        permissions: ["Show.SLADefinition"],
        label: "SLA Kategori Tanımları",
    },
    {
        permissions: ["Services"],
        label: "Hizmet Tanımları",
    }, 
    {
        permissions: ["ServicesTypes"],
        label: "Hizmet Tipleri",
        link: "/settings/services",
    },
    {
        permissions: ["Services.Categories"],
        label: "Hizmet Kategorileri",
        link: "/settings/table/servicecategory",
    },
    {
        permissions: ["Services.CancelReason"],
        label: "Hizmet İptal Nedenleri",
        link: "/settings/table/servicecancelreason",
    },
    {
        permissions: ["Services.RejectReason"],
        label: "Hizmet Reddi Nedenleri",
        link: "/settings/table/servicerejectionreasons",
    },
    {
        permissions: ["Services.CreateReason"],
        label: "Hizmet Oluşturma Nedenleri",
        link: "/settings/table/servicecreatreason",
    },
    {
        permissions: ["Services.SupplierChangedRegion"],
        label: "Tedarikçi Bölge Değiştirme Nedenleri",
        link: "/settings/table/changesupplierreason",
    },
    {
        permissions: ["Services.ReasonForChangingInfo"],
        label: "Hizmet Bilgileri Değiştirme Nedenleri",
        link: "/settings/table/changeserviceinformationreason",
    },
    {
        permissions: ["Services.DayExtensionReason"],
        label: "Gün Uzatımı Nedenleri",
        link: "/settings/table/dayextentionreason",
    }, //Teminat Aşım Nedenleri Tanım Ekranı
    {
        permissions: ["Services.ReasonForChageDeliveryAddress"],
        label: "Teslim Adresi Değişikliği Nedenleri",
        link: "/settings/table/changedeliveryreason",
    },
    {
        permissions: ["Services.OverrunAssuranceDefinition"],
        label: "Teminat Aşım Nedenleri Tanım Ekranı",
        link: "/settings/table/assuranceoverrunreason",
    },
    {
        permissions: ["Show.DashbordAnomalyReport"],
        label: "Dashboard Anomali Raporları",
        link: "/operator/dashboard-anomaly",
    },
    {
        permissions: ["Prices"],
        label: "Fiyat/Ödeme Tanımları",
    },
    {
        permissions: ["PricesList"], //OKEY
        label: "Fiyat Listeleri",
        link:'/settings/prices'
    },
    {
        permissions:["PricesListCanceledReasons"],
        label:"Fiyat Listeleri İptal Nedenleri",
        link:"/settings/table/pricecancelreason"
    },
    {
        permissions:["PayerTypes"],
        label:"Yüklenici Tipleri",
        link:"/settings/table/payertype"
    },
    {
        permissions:["CurrencyTypes"],
        label:"Ödeme Birimi Tipleri",
        link:"/settings/table/currencytype"
    },
    {
        permissions:["PaymentHeaderTypes"],
        label:"Ödeme Başlık Tipleri",
        link:"/settings/table/paymentheader"
    },
    {
        permissions:["PaymentTypes"],
        label:"Ödeme Tipleri",
        link:"/settings/table/paymenttype"
    },
    {
        permissions:["PaymentDetailTypes"],
        label:"Ödeme Detay Tipleri",
        link:"/settings/table/paymentdetailtype"
    },
    {
        permissions:["PaymentReasons"],
        label:"Ödeme Nedenleri",
        link:"/settings/paymentreason"
    },
    {
        permissions:["Currencies"],
        label:"Para Birimleri",
        link:"/settings/table/currency"
    },
    {
        permissions:["ValueDate"],
        label:"Valör Gün",
        link:"/settings/table/valuedate"
    },
    {
        permissions:["ObjectReason"],
        label:"İtiraz Nedenleri",
        link:"/settings/table/objectreason"
    },

    {
        permissions: ["ComplaintsDefinitions"],
        label: "Şikayet Tanımları",
    },
    {
        link: "/settings/table/complaintreason",
        permissions: ["ComplaintReasons"],
        label: "Şikayet Nedenleri",
    },
    {
        link: "/settings/table/complaintsource",
        permissions: ["ComplaintSources"],
        label: "Şikayet Kaynakları",
    },
    {
        link: "/operator/userlist",  //OKEY
        permissions: ["UserList"],
        label: "Kullanıcılar",
    },
    {
        link: "/settings/table/usergroup",
        permissions: ["UserGroups"],
        label: "Kullanıcı Grupları",
    },
    {
        link: "/settings/table/rolegroup",
        permissions: ["RoleGroups"],
        label: "Rol Grupları",
    }, //permissiongroup
    {
        link: "/settings/table/permissiongroup",
        permissions: ["PermissionsGroups"],
        label: "Yetki Grupları",
    }, //permissiongrou
    {
        link: "/settings/permission",  //OKEY
        permissions: ["PermissionsDefination"],
        label: "Yetki Tanımları",
    },
    {
        link: "/settings/role",
        permissions: ["RolesDefination"],
        label: "Rol Tanımları",
    },
    {
        permissions: ["UserRoles"],
        label: "Kullanıcılar ve Roller",
    },
    {
        permissions: ["Statuses"],
        label: "Durum Tanımları",
    },
    {
        permissions: ["Definition"],
        label: "Tanımlar",
    },
    {
        link: "/operator/reporter",
        permissions: ["Reporter"],
        label: "Finans Raporu",
    },
    {
        link: "/operator/customerreports",
        permissions: ["CustomerReports"],
        label: "Müşteri Raporu",
    },
    {
        link: "/operator/supplierpricereport",
        permissions: ["SupplierPriceReport"],
        label: "Tedarikçi Fiyat Raporu",
    },
    {
        link: "/operator/supplierreport",
        permissions: ["SupplierReport"],
        label: "Tedarikçi Raporu",
    },
    {
        link: "/operator/supplierServiceDashboard",
        permissions: ["Show.Supplier-Service-Dashboard"],
        label: "Tedarikçi Hizmet Gösterge Paneli",
    },
    {
        link: "/operator/surveyreport",
        permissions: ["SurveyReport"],
        label: "Memnuniyet Anket Raporu",
    },
    {
        link: "/operator/assistanceFileReport",
        permissions: ["AssistanceFileDetailReport"],
        label: "Asistans Dosyası Detay Raporu",
    },
    {
        link: "/operator/userBasedOrganisationReport",
        permissions: ["UserBasedOrganizationDetailRepor"],
        label: "Kullanıcı Bazlı Organizasyon Detay Raporu",
    },
    {
        link: "/operator/userBasedSummaryReport",
        permissions: ["UserBasedSummaryReport"],
        label: "Kullanıcı Bazlı Özet Raporu",
    },
    {
        link: "/operator/servicesReport",
        permissions: ["ServicesReport"],
        label: "Hizmet Raporu",
    },
    {
        link: "/operator/service-statu-change-report",
        permissions: ["ServiceStatusChangeReport"],
        label: "Hizmet Durum Değişim Raporu",
    },
    {
        link: "/operator/ikm-service-day-extentaion",
        permissions: ["SubstitutionServiceDayExtensionReport"],
        label: "İkame Hizmeti Gün Uzama Raporu",
    },
    {
        link: "/operator/towing-service-report",
        permissions: ["TowServiceReport"],
        label: "Çekici Hizmet Raporu",
    },
    {
        link: "/operator/markReport",
        permissions: ["MarkerReport"],
        label: "İz Raporu",
    },
    {
        link: "/operator/googleLogs",
        permissions: ["GoogleLogs"],
        label: "Google Logs",
    },
    {
        link: "/operator/userGuides",
        permissions: ["Show.CreateUserGuides"],
        label: "Kullanıcı Kılavuzları",
    },
    {
        link: "/settings/agency-region-definitions",
        permissions: ["Show.AgencyRegionDefinitions"],
        label: "Acente Bölge Tanımları",
    },
    {
        link: "/operator/slaCategoriesDashboard",
        permissions: ["Show.SlaDashboard"],
        label: "SLA Kategori Gösterge Paneli",
    },
    {
        label: 'Kampanya Tanımları', 
        link: "/campaigns/campaign-create", 
        permissions: ["campaign-create"],
    },
    {
        link: "/operator/dashboard-assurance",
        permissions: ["updateAssuranceForServices"],
        label: "Teminatsız Hizmetler",
    },
    {
        permissions: ["Report"],
        label: "Raporlar",
    },

    {
        label:"MIP Tanımları",
        permissions:["MIPCategories"]
    },
    {
        label:"Mip Kategori Tanımları",
        permissions:["MIPCategories"],
        link:"/settings/mip-and-octopus"
    },

    {
        label:"Zeplin Tanımları",
        permissions:["ZeppelinDefinitions"]
    },
    {
        label:"Grup Kodu Tanımları",
        permissions:["ZeppelinGroupCode"],
        link:"/settings/zeplin-group-codes"
    },
    {
        label:"Ofis Tanımları",
        permissions:["ZeppelinOffices"],
        link:"/settings/zeplin-offices"
    },


    {
        label:"Durumlar",
        permissions:["StatusesDefinition"],
        link:"/settings/status"
    },
    {
        label:"Durum Grup Tanımları",
        permissions:["StatusesGroupDefinition"],
        link:"/settings/statusgroup"
    },
    {
        label:"Durum Grup Özetleri",
        permissions:["StatusesGroupSummaryDefinition"],
        link:"/settings/table/statusgroupsummary"
    },

    {
        label:"Görev Tanımları",
        permissions:["TaskDefinition"],
    },
    {
        label:"Takım Tanımları",
        permissions:["TeamDefinition"],
        link:"/operator/team"
    },
    {
        label:"Görev Kategori Tanım",
        permissions:["TaskCategoryDefinition"],
        link:"/settings/table/taskcategory"
    },
    {
        label:"Görev Alt Kategori Tanım",
        permissions:["TaskSubCategoryDefinition"],
        link:"/settings/table/tasksubcategory"
    },


    {
        label:"Görev",
        permissions:["Task"]
    },
    {
        link: "/operator/autoTask",
        permissions: ["AutoTask.View"],
        label: "Otomatik Görev Düzenleme",
    },
    {
        link: "/operator/tasks",
        permissions: ["TaskList"],
        label: "Görev Listesi",
    },

];
@Injectable({
    providedIn: "root"
})
export class PermissionsService {
    constructor() { }


    setMenuVisibility(menu: MenuItem[]) {
        for (let itemIndex = 0; itemIndex < menu.length; itemIndex++) {
            const menuItem = menu[itemIndex];

            if (menuItem.visible === false) {
                //  Başka bir kontrolden görünmemesi sağlanması durumu
                continue;
            }

            if (menuItem.items && menuItem.items.length > 0) {
                //  Gurup olması durumu
                menuItem.items = this.setMenuVisibility(menuItem.items);
            }

            const permissionItem = permissionsMenu.find(permission => permission.label === menuItem.label);
            if (!permissionItem) {
                // Seperatörler gibi labeli olmayan itemler durumu
                continue;
            }

            menuItem.visible = this.permissionCheck(permissionItem.permissions);
        }

        return menu;
    }



    private permissionCheck(permissions: string[]) {
        const savedPermissions = localStorage.getItem('user_roles');
        const item = JSON.parse(savedPermissions);

        for (let index = 0; index < permissions.length && item; index++) {
            const permission = permissions[index];
            const hasPermission = item.find((i: any) => i?.permissionName?.toLocaleUpperCase("tr") === permission?.toLocaleUpperCase("tr"))?.isList;

            if (!hasPermission) {
                return false;
            }
        }
        return true;
    }
}
